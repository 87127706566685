<template>
  <div class="wrapper">
    <a href="team/#formulario" v-smooth-scroll="{ duration: 1500, offset: -150 }">
      <p class="city">{{ member.city }}</p>
      <img alt="member" loading="lazy" :src="member.image" />
      <p class="name">{{ member.name }}</p>
      <p class="position">{{ member.position }}</p>
      <p class="contact">{{ $t('message.to_contact') }}</p>
    </a>
  </div>
</template>

<script>
export default {
  props: ['member']
}
</script>

<style lang="scss" scoped>
.wrapper {
  text-align: left;
  width: 199px;
  margin: 10px 10px 50px 10px;
  transition: 0.3s ease;
}

p.city {
  background: #414552;
  color: white;
  text-align: center;
  padding: 5px;
}

.contact {
  background: #009090;
  color: white;
  padding: 5px;
  text-align: center;
  margin-top: 10px;
}

.name, 
.position {
  margin: 0;
  font-size: 0.9rem;
  letter-spacing: -0.5px;
}

.position {
  font-weight: 600;
}

.contact {
  cursor: pointer;
}

.contact:hover {
  background: #184a5f;
}

.wrapper:hover {
  transform: scale(1.05);
}

a {
  color: black;
}

a:hover {
  text-decoration: none;
}
</style>
