<template>
  <div class="team-members">
    <TeamMember
      v-for="member in members"
      :key="member.id" :member="member"
    />
  </div>
</template>

<script>
import TeamMember from './TeamMember.vue';

export default {
  data() {
    return {
      members: [
        { 
          id: 1,
          city: 'Madrid',
          image: 'https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/team/Jose-Manuel-Cosio.png',
          name: 'Jose Manuel Cosío',
          position: 'Responsable de Desarrollo de negocio. Madrid Juan Bravo',
        },
        //{
        //  id: 2,
        //  city: 'Alicante',
        //  image: 'https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/team/Susana-Prieto-Heredia.png',
        //  name: 'Susana Prieto Heredia',
        //  position: 'Responsable de Desarrollo de negocio. Alicante',
        //},
        {
          id: 3,
          city: 'Bilbao',
          image: 'https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/team/Elena-Zalbide-Elorduy.png',
          name: 'Elena Zalbide Elorduy',
          position: 'Responsable de Desarrollo de negocio. Alicante',
        },
        {
          id: 4,
          city: 'La Rioja',
          image: 'https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/team/Roberto-Ruiz-Yecora.png',
          name: 'Roberto Ruiz Yécora',
          position: 'Responsable de Desarrollo de negocio. La Rioja',
        },
        {
          id: 5,
          city: 'Castellón',
          image: 'https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/team/Carolina-Salvador-Moliner.png',
          name: 'Carolina Salvador Moliner',
          position: 'Responsable de Desarrollo de negocio. Castellón',
        },
        // {
        //   id: 6,
        //   city: 'Tenerife',
        //   image: 'https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/team/Juan-Rojas.png',
        //   name: 'Juan Rojas',
        //   position: 'Responsable de Desarrollo de negocio. Tenerife',
        // },
        //{
        //  id: 7,
        //  city: 'Guatemala',
        //  image: 'https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/team/Jose-Manuel-Martinez.png',
        //  name: 'José Manuel Martinez',
        //  position: 'Responsable de Desarrollo de negocio. Guatemala',
        //},
        //{
        //  id: 8,
        //  city: 'Rep. Dominicana',
        //  image: 'https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/team/Pedro-Pimentel.png',
        //  name: 'Pedro Pimentel',
        //  position: 'Responsable de Desarrollo de negocio. Rep. Dominicana',
        //},
        //{
        //  id: 9,
        //  city: 'Asturias',
        //  image: 'https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/team/Pedro-Luis-Alonso-Tuda.png',
        //  name: 'Pedro Luis Alonso Tuda',
        //  position: 'Responsable de Desarrollo de negocio. Asturias',
        //},
        //{
        //  id: 10,
        //  city: 'Costa Rica',
        //  image: 'https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/team/Agustin-Ruiz-Saiz.png',
        //  name: 'Agustín Ruiz Saiz',
        //  position: 'Responsable de Desarrollo de negocio. Madrid y Costa Rica',
        //}
      ]
    }
  },
  components: {
    TeamMember
  }
}
</script>

<style>
  .team-members {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
</style>