<template>
  <div class="wrapper">
    <div class="content">
      <div class="container">
        <section class="our-work">
          <b-row class="head">
            <h2>{{ $t('message.team_title1') }}</h2>
            <h2>{{ $t('message.team_title2') }}</h2>
            <p>{{ $t('message.team_subtitle') }}</p>
          </b-row>
          <b-row class="columns">
            <b-col class="col-lg-6 left">
              <div class="element">
                <div class="head">
                  <div class="icon">
                    <font-awesome-icon :icon="['fas', 'user-tie']" class="circled-icon"/>
                  </div>
                  <div class="titles">
                    <h5>{{ $t('message.proccess') }}</h5>
                    <h4>{{ $t('message.commercial') }}</h4>
                  </div>
                </div>
                <p>{{ $t('message.team_work1') }}</p>
              </div>
              <div class="element">
                <div class="head">
                  <div class="icon">
                    <font-awesome-icon :icon="['fas', 'handshake']" class="circled-icon"/>
                  </div>
                  <div class="titles">
                    <h5>{{ $t('message.proccess_of') }}</h5>
                    <h4>{{ $t('message.advise') }}</h4>
                  </div>
                </div>
                <p>{{ $t('message.team_work2') }}</p>
              </div>
            </b-col>
            <b-col class="col-lg-6 right">
              <div class="element">
                <div class="head">
                  <div class="icon">
                    <font-awesome-icon :icon="['fas', 'user-check']" class="circled-icon"/>
                  </div>
                  <div class="titles">
                    <h5>{{ $t('message.proccess_of') }}</h5>
                    <h4>{{ $t('message.recruitment_selection') }}</h4>
                  </div>
                </div>
                <p>{{ $t('message.team_work3') }}</p>
              </div>
              <div class="element">
                <div class="head">
                  <div class="icon">
                    <font-awesome-icon :icon="['fas', 'user-graduate']" class="circled-icon"/>
                  </div>
                  <div class="titles">
                    <h5>{{ $t('message.proccess_of') }}</h5>
                    <h4>{{ $t('message.training') }}</h4>
                  </div>
                </div>
                <p>{{ $t('message.team_work4') }}</p>
              </div>
            </b-col>
          </b-row>
        </section>
      </div>
    </div>
    <section id="our-team" class="our-team">
      <div class="container">
        <b-row class="head">
          <font-awesome-icon :icon="['fas', 'users']" class="circled-icon"/>
          <h2>{{ $t('message.our_team_title') }}</h2>
        </b-row>
        <div class="contents">
          <p>{{ $t('message.service1') }}</p>
          <!-- <div class="line">
            <font-awesome-icon :icon="['fas', 'check']" class="icon-check"/>
            <span>{{ $t('message.service1') }}</span>
          </div> -->
          <!-- <div class="line">
            <font-awesome-icon :icon="['fas', 'check']" class="icon-check"/>
            <span>{{ $t('message.service2') }}</span>
          </div> -->
          <h3>{{ $t('message.service2') }}</h3>
          <div class="line">
            <font-awesome-icon :icon="['fas', 'check']" class="icon-check"/>
            <span>Acceso a herramientas del club para búsqueda y selección de personal:</span>
            <ul>
              <li>Portal de empleo interno</li>  
              <li>Software</li>  
            </ul>
          </div>
          <br>
          <div class="line">
            <font-awesome-icon :icon="['fas', 'check']" class="icon-check"/>
            <span>Publicaciones ilimitadas en los portales de empleo más relevantes</span>
          </div>
          <div class="line">
            <font-awesome-icon :icon="['fas', 'check']" class="icon-check"/>
            <span>Soporte y viralización de las publicaciones de los miembros en Redes Sociales</span>
          </div>
          <br>
          <div class="line">
            <font-awesome-icon :icon="['fas', 'check']" class="icon-check"/>
            <span>Soporte a miembros del club</span>
          </div>
          <div class="line">
            <font-awesome-icon :icon="['fas', 'check']" class="icon-check"/>
            <span>Rápidos en responder: 48 horas desde la recepción del perfil. </span>
          </div>
          <div class="line">
            <font-awesome-icon :icon="['fas', 'check']" class="icon-check"/>
            <span>Inclusión de perfil del asociado página web específica del club. <a href="#" style="vertical-align: top;">Ver ejemplo</a></span>
          </div>
          <div class="line">
            <font-awesome-icon :icon="['fas', 'check']" class="icon-check"/>
            <span>Acceso a programas de desarrollo directivo nivel ejecutivo</span>
          </div>
          <div class="line">
            <font-awesome-icon :icon="['fas', 'check']" class="icon-check"/>
            <span>Acceso a plataforma Elearning de entrenamiento directivo</span>
          </div>
          <div class="line">
            <font-awesome-icon :icon="['fas', 'check']" class="icon-check"/>
            <span>Acceso a webinars con expertos</span>
          </div>
          <div class="line">
            <font-awesome-icon :icon="['fas', 'check']" class="icon-check"/>
            <span>Acceso en eventos locales y nacionales</span>
          </div>
          <div class="line">
            <font-awesome-icon :icon="['fas', 'check']" class="icon-check"/>
            <span>Acceso a desayunos de negocios</span>
          </div>
          <div class="line">
            <font-awesome-icon :icon="['fas', 'check']" class="icon-check"/>
            <span>Acceso a laboratorio de tecnologías</span>
          </div>
          <div class="line">
            <font-awesome-icon :icon="['fas', 'check']" class="icon-check"/>
            <span>Asesoramiento en venta Ecommerce</span>
          </div>
          <div class="line">
            <font-awesome-icon :icon="['fas', 'check']" class="icon-check"/>
            <span>Asesoramiento en transformación digital</span>
          </div>
          <div class="line">
            <font-awesome-icon :icon="['fas', 'check']" class="icon-check"/>
            <span>Potente red  profesional (mundial) de networking y oportunidades de negocio</span>
          </div>
        </div>
        <TeamMembers />
      </div>
      <ContactsForm />
    </section>
    <b-row class="clearfix" style="height: 50px;background: rgb(243, 243, 243);"></b-row>
  </div>
</template>

<script>
import ContactsForm from '../../components/ContactsForm.vue';
import TeamMembers from '../../components/team/TeamMembers.vue';

export default {
  metaInfo() {
    return {
      title: this.$t('empleabilidad.our_team'),
      meta: [
        // OpenGraph
        { vmid: "og:title", property: "og:title",  content: this.$t('empleabilidad.our_team') + ' - Club de Ejecutivos' },
        { vmid: "og:type", property: 'og:type', content: 'website' },
        // Google / Schema.org markup:
        { itemprop: 'name', content: this.$t('empleabilidad.our_team') + ' - Club de Ejecutivos' },
      ]
    }
  },
  components: {
    ContactsForm,
    TeamMembers
  }
}
</script>

<style scoped lang="scss">
.content {
  background-color: #d3d5d6;
  text-align: left;
}

.content .container {
  padding-top: 50px;
}

.columns {
  /* text-align: left; */
}

.columns .left {
  border-right: 2px solid #2c3e50;
}

.circled-icon {
  padding: 10px;
  border: 2px solid#2c3e50;
  border-radius: 50%;
  /* font-size : 60px; */
  height: 60px;
  width: 60px;
  text-align: center;
}

.columns {
  margin: 50px 0 0;

  .left {
    margin-bottom: 15px;
    padding-left: 0;
  }

  .right {
    padding-left: 60px;
  }
}

// SECTION OUR WORK
.our-work {
  .head {
    margin: 0;

    h2 {
      font-weight: 800;
      font-size: 1.8rem;
    }

    p {
      font-weight: 700;
    }
  }

  .element {
    height: 400px;

    .head {
      display: flex;
      height: 75px;

      .titles {
        h4 {
          font-weight: 800;
        }

        h5 {
          font-size: 1.5rem;
        }
      }
    }
    p {
      padding: 40px 40px 40px 0;
    }

    .icon {
      margin-right: 20px;
      padding: 0;
    }
  }
}


// SECTION OUR TEAM
.our-team {
  background: #f4edee;

  .container {
    padding-left: 30px;
  }

  .head {
    padding: 50px 0;
    flex-wrap: nowrap;

    h2 {
      margin: auto 0 auto 25px;
      text-align: left;
    }

    .circled-icon {
      height: 75px;
      width: 75px;
    }
  }

  .contents {
    text-align: left;
    margin-bottom: 50px;

    .icon-check {
      color: #009090;
      height: 25px;
      width: 25px;
      margin-right: 10px;
    }

    .line {
      span {
        vertical-align: top;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .our-team {
    .head {
      flex-wrap: wrap;
      justify-content: center;

      h2 {
        text-align: center;
        font-size: 1.4rem;
        margin: 0;
      }
    }
  }

  .our-work {
    .element {
      p {
        padding: 0;
      }
    }

    .columns {
      .left {
        padding-right: 0;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .our-work {
    .columns {
      .left {
        border-right: none;
        // padding-right: 60px;
      }

      .right {
        padding-left: 0;
      }
    }

    .element {
      height: unset;
      margin-bottom: 40px;

      .head {
        height: unset;
      }
    }
  }
}
</style>